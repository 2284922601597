function Github({ color }: { color: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-[20.62px] h-[20px] mt-1"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M10.3079 0C7.85995 0.0012701 5.49228 0.868282 3.62825 2.446C1.76421 4.02373 0.525362 6.20928 0.133215 8.61186C-0.258932 11.0144 0.221191 13.4774 1.48774 15.5603C2.7543 17.6432 4.72468 19.2102 7.04658 19.9812C7.55882 20.0762 7.75177 19.7588 7.75177 19.4888C7.75177 19.2189 7.74152 18.4362 7.73811 17.5805C4.86952 18.2002 4.26337 16.37 4.26337 16.37C3.79551 15.1816 3.11934 14.8692 3.11934 14.8692C2.18364 14.2342 3.18936 14.2461 3.18936 14.2461C4.22581 14.3191 4.7705 15.3038 4.7705 15.3038C5.68913 16.8708 7.18317 16.4175 7.77055 16.1527C7.86275 15.4888 8.13084 15.0372 8.42623 14.7809C6.13478 14.5228 3.72721 13.6434 3.72721 9.7147C3.71301 8.69582 4.09326 7.7105 4.78928 6.9626C4.68341 6.70453 4.32996 5.6621 4.89001 4.24615C4.89001 4.24615 5.75572 3.97111 7.72616 5.29708C9.41629 4.83751 11.1995 4.83751 12.8896 5.29708C14.8584 3.97111 15.7223 4.24615 15.7223 4.24615C16.2841 5.6587 15.9307 6.70114 15.8248 6.9626C16.523 7.71062 16.9041 8.69766 16.8886 9.71809C16.8886 13.6552 14.4759 14.5228 12.181 14.7758C12.5498 15.095 12.8794 15.718 12.8794 16.6756C12.8794 18.0474 12.8674 19.151 12.8674 19.4888C12.8674 19.7622 13.0535 20.0813 13.576 19.9812C15.8982 19.2101 17.8688 17.6428 19.1353 15.5595C20.4018 13.4763 20.8817 11.013 20.4891 8.61018C20.0965 6.20738 18.8571 4.02181 16.9924 2.44436C15.1277 0.866906 12.7595 0.000465046 10.3113 0H10.3079Z"
        fill={color}
      />
      <path
        // eslint-disable-next-line max-len
        d="M3.90114 14.7215C3.87894 14.7724 3.79698 14.7877 3.73039 14.7521C3.6638 14.7164 3.61428 14.6502 3.63819 14.5976C3.66209 14.5449 3.74234 14.5313 3.80894 14.567C3.87553 14.6026 3.92675 14.6706 3.90114 14.7215Z"
        fill="#0E0333"
      />
      <path
        // eslint-disable-next-line max-len
        d="M4.31948 15.185C4.28411 15.2027 4.24366 15.2076 4.20504 15.1989C4.16641 15.1903 4.13202 15.1685 4.10775 15.1374C4.04116 15.0661 4.02749 14.9676 4.07871 14.9235C4.12994 14.8794 4.22215 14.8997 4.28874 14.971C4.35533 15.0423 4.3707 15.1408 4.31948 15.185Z"
        fill="#0E0333"
      />
      <path
        // eslint-disable-next-line max-len
        d="M4.72589 15.774C4.66271 15.8181 4.55513 15.774 4.49537 15.6857C4.47884 15.6699 4.4657 15.6509 4.45673 15.6299C4.44775 15.6089 4.44312 15.5863 4.44312 15.5635C4.44312 15.5407 4.44775 15.5181 4.45673 15.4971C4.4657 15.4761 4.47884 15.4571 4.49537 15.4412C4.55855 15.3988 4.66612 15.4412 4.72589 15.5278C4.78565 15.6144 4.78736 15.7299 4.72589 15.774Z"
        fill="#0E0333"
      />
      <path
        // eslint-disable-next-line max-len
        d="M5.27743 16.3445C5.22108 16.4073 5.10668 16.3904 5.01277 16.3055C4.91886 16.2206 4.89665 16.1051 4.953 16.044C5.00935 15.9829 5.12375 15.9999 5.22108 16.0831C5.31841 16.1662 5.33719 16.2834 5.27743 16.3445Z"
        fill="#0E0333"
      />
      <path
        // eslint-disable-next-line max-len
        d="M6.05105 16.6773C6.02544 16.7571 5.90933 16.7927 5.79322 16.7588C5.67711 16.7248 5.60028 16.6297 5.62247 16.5482C5.64467 16.4667 5.76249 16.4294 5.88031 16.4667C5.99812 16.5041 6.07325 16.5941 6.05105 16.6773Z"
        fill="#0E0333"
      />
      <path
        // eslint-disable-next-line max-len
        d="M6.89438 16.7351C6.89438 16.8183 6.79877 16.8896 6.67583 16.8913C6.55289 16.893 6.45215 16.8251 6.45215 16.7419C6.45215 16.6587 6.54776 16.5874 6.6707 16.5857C6.79364 16.584 6.89438 16.6502 6.89438 16.7351Z"
        fill="#0E0333"
      />
      <path
        // eslint-disable-next-line max-len
        d="M7.67982 16.6043C7.69518 16.6875 7.60981 16.774 7.48687 16.7944C7.36393 16.8148 7.25636 16.7656 7.24099 16.6841C7.22562 16.6026 7.31441 16.5143 7.43394 16.4922C7.55346 16.4701 7.66445 16.5211 7.67982 16.6043Z"
        fill="#0E0333"
      />
    </svg>
  );
}

export default Github;
